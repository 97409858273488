<template>
    <Modal class="custom-modal doctor-filter">
        <ModalHeader class="custom-header"
            ><h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <form class="form-modal">
                <div class="form-group">
                    <label class=" col-form-label form-label">ID</label>
                    <div>
                        <input
                            v-model="filterData.id"
                            type="number"
                            placeholder="Cari ID Ketidakhadiran"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label">Dokter</label>
                    <div>
                        <multiselect
                            class="holiday-multiselect"
                            track-by="id"
                            label="nama"
                            :allow-empty="true"
                            :multiple="true"
                            :close-on-select="false"
                            v-model="filterData.selectedDoctors"
                            :options="doctorOptions"
                            placeholder="Pilih Dokter"
                            :searchable="true"
                            :loading="isDoctorSelectLoading"
                            @search-change="searchDoctor"
                        >
                            <template
                                slot="selection"
                                slot-scope="{ values, isOpen }"
                                ><span
                                    class="multiselect__single"
                                    v-if="values.length &amp;&amp; !isOpen"
                                    >{{ values.length }} Dokter Terpilih</span
                                ></template
                            >
                            <!-- @search-change="searchDoctor" -->
                        </multiselect>
                        <div
                            style="margin-top: 25px;max-height: 100px;overflow: auto;"
                        >
                            <div
                                style="padding: 0 20px 0 20px;"
                                class="d-flex justify-content-between"
                                v-for="(dokter,
                                index) in filterData.selectedDoctors"
                                :key="index"
                            >
                                <p>{{ index + 1 }}. &nbsp;{{ dokter.nama }}</p>
                                <button
                                    @click.prevent="deleteDokter(index)"
                                    class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
                                    title="Hapus"
                                >
                                    <TrashIcon :strokeColor="'#C2283C'" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label">Periode</label>
                    <div>
                        <!-- <DatePicker
                            class="custom-datepicker"
                            :changedValue.sync="filterData.tanggal"
                            :id-date-picker="
                                'jadwal_date_start_' + 'ketidakhadiran'
                            "
                            :picker-value="filterData.tanggal"
                            place-holder="Tanggal"
                            :is-validate="false"
                            start-date=""
                            end-date=""
                        >
                        </DatePicker> -->
                        <DateRangePicker
                            :id-date-picker="
                                'jadwal_date_start_' + 'ketidakhadiran'
                            "
                            place-holder="DD/MM/YYYY - DD/MM/YYYY"
                            :changedValueStart.sync="filterData.dateRange.start"
                            :changedValueEnd.sync="filterData.dateRange.end"
                            :periodeAwal="initialDateRange.start"
                            :periodeAkhir="initialDateRange.end"
                        >
                        </DateRangePicker>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label">Hari</label>
                    <div>
                        <multiselect
                            class="holiday-multiselect day-custom"
                            :allow-empty="true"
                            :multiple="false"
                            :close-on-select="true"
                            v-model="filterData.hari"
                            :options="daysOptions"
                            placeholder="Pilih Hari"
                            :searchable="false"
                        >
                        </multiselect>
                    </div>
                </div>
            </form>
        </ModalBody>
        <div
            style="margin-left:25px;margin-right:25px;"
            class="form-divider"
        ></div>
        <ModalFooter class="custom-footer">
            <div>
                <button
                    @click.prevent="saveFilter"
                    class="btn btn-primary custom-modal-button"
                >
                    Terapkan
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

import EyeIcon from "../../../_icons/EyeIcon.vue";
import EyeIconSlashed from "../../../_icons/EyeIconSlashed.vue";
import TrashIcon from "../../../_icons/TrashIcon.vue";

import DatePicker from "../../../_general/DatePicker.vue";
import JadwalDay from "../../../_select/JadwalDay.vue";
import DateRangePicker from "../../../_general/CustomDateRangePicker.vue";

import Multiselect from "vue-multiselect";
export default {
    name: "KlinikModalFilter",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,

        EyeIcon,
        EyeIconSlashed,
        TrashIcon,

        JadwalDay,
        DatePicker,
        DateRangePicker,

        Multiselect
    },
    mixins: [ModalMixin],
    data() {
        var vx = this;
        return {
            modalTitle: "Filter Ketidakhadiran",
            isDoctorSelectLoading: false,
            daysOptions: [
                "Senin",
                "Selasa",
                "Rabu",
                "Kamis",
                "Jumat",
                "Sabtu",
                "Minggu"
            ],
            searchDoctor: _.debounce(async query => {
                try {
                    if (query) {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchDoctor ==
                                "function"
                            ) {
                                vx.isDoctorSelectLoading = true;
                                const resp = await vx.componentData.searchDoctor(
                                    query
                                );
                                vx.isDoctorSelectLoading = false;
                                vx.doctorOptions = resp;
                            }
                        }
                    } else {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchDoctor ==
                                "function"
                            ) {
                                vx.isDoctorSelectLoading = true;
                                const resp = await vx.componentData.searchDoctor();
                                vx.isDoctorSelectLoading = false;
                                vx.doctorOptions = resp;
                            }
                        }
                    }
                } catch (error) {
                    vx.isDoctorSelectLoading = false;
                    console.log(error);
                }
            }, 500),
            doctorOptions: [],
            filterData: {
                hari: null,
                tanggal: null,
                dateRange: {
                    start: null,
                    end: null
                },
                id: null,
                selectedDoctors: []
            },
            dateRange: {
                start: null,
                end: null
            },
            initialDateRange: { start: "", end: "" },
            // klinikTypeOptions: [
            //     { nama: "Vue.js", language: "JavaScript" },
            //     { nama: "Rails", language: "Ruby" },
            //     { nama: "Sinatra", language: "Ruby" },
            //     { nama: "Laravel", language: "PHP", $isDisabled: true },
            //     { nama: "Phoenix", language: "Elixir" }
            // ]
        };
    },
    methods: {
        deleteDokter(index) {
            this.filterData.selectedDoctors.splice(index, 1);
        },
        saveFilter() {
            if (this.componentData) {
                if (typeof this.componentData.action == "function") {
                    this.componentData.action(this.filterData);
                }
            }
        }
    },
    created() {
        this.searchDoctor();
        if (this.componentData) {
            if (this.componentData.initialData) {
                if (this.componentData.initialData.id) {
                    this.filterData.id = this.componentData.initialData.id;
                }
                if (this.componentData.initialData.selectedDoctors) {
                    this.filterData.selectedDoctors = this.componentData.initialData.selectedDoctors;
                }
                if (this.componentData.initialData.dateRange) {
                    this.filterData.dateRange = this.componentData.initialData.dateRange;
                    this.initialDateRange = this.componentData.initialData.dateRange;
                }
                if (this.componentData.initialData.hari) {
                    this.filterData.hari = this.componentData.initialData.hari;
                }
            }
        }
    },
    mounted() {
        let DatePickers = document.querySelectorAll(".daterangepicker");
        DatePickers.forEach(el => {
            el.style.zIndex = "1055";
        });
    }
};
</script>

<style></style>
