<template>
    <div>
        <base-header
            class="sticky-base-header"
            base-title="Master Ketidakhadiran"
        ></base-header>
        <KetidakhadiranScreen
            :methodObject="methodObject"
            :buttonMethod="buttonMethod"
            :filterList="filterList"
            :isFetching="isFetching"
            :permissionObject="permissionObject"
        >
        </KetidakhadiranScreen>
    </div>
</template>

<script>
import { Event } from "vue-tables-2";
import BaseHeader from "../../_base/BaseHeader.vue";

import ModalService from "../../../services/modal.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const ketidakhadiranRepo = RepositoryFactory.get("ketidakhadiran");
import createRequestOption from "../../../repositories/RequestParamUtil";
import BlockUI from "../../_js/BlockUI";

import KetidakhadiranFilter from "../component/ketidakhadiranModal/ketidakhadiranFilter.vue";
import AddKetidakhadiranModal from "../component/ketidakhadiranModal/AddKetidakhadiranModal.vue";

import KetidakhadiranScreen from "./KetidakhadiranScreen.vue";

import DataClass from "../class/ketidakhadiran-data-class";
import PermissionClass from "../class/permission-class";

import SwalCustom from "../../_general/SwalCustom.vue";
const SweetAlert = Vue.extend(SwalCustom);

export default {
    name: "MasterKlinikController",
    components: {
        KetidakhadiranScreen,
        BaseHeader
    },
    data() {
        var vx = this;
        return {
            blockUi: new BlockUI(),
            filterList: {},
            isFetching: false,
            methodObject: {
                controllerReqFunctionMethod: vx.controllerReqFunction,
                controllerRespFunctionMethod: vx.controllerRespFunction,
                openModalFilterMethod: vx.openModalFilter,
                openKetidakhadiranModalMethod: vx.openKetidakhadiranModal,
                deleteIndvFIlterMethod: vx.deleteIndvFIlter,
                filterItemsDataMethod: vx.filterItemsData,
                deleteAllFilterMethod: vx.deleteAllFilter,
                getDataMethod: vx.emitFilterQuery
            },
            buttonMethod: {
                deleteAbsentConfirmationMethod: vx.deleteAbsentConfirmation
            },
            permissionObject: {}
        };
    },
    watch: {
        filterList: {
            handler(val, oldVal) {
                this.$router.push(
                    {
                        name: "ketidakhadiran",
                        query: this.filterListToQuery(this.filterList)
                    },
                    e => {
                        this.routeQueryToFilterList();
                        // this.emitFilterQuery();
                    }
                );
            },
            deep: true
        }
    },
    methods: {
        routeQueryToFilterList() {
            let filterQueryNames = [
                "id",
                "dateRange",
                "hari",
                "selectedDoctors"
                // "namaAwamInggris",
                // "namaInggris",
                // "klinikType"
            ];
            filterQueryNames.forEach(name => {
                if (this.$route.query[name]) {
                    if (name == "dateRange" || name == "selectedDoctors") {
                        this.filterList[name] = JSON.parse(
                            atob(this.$route.query[name])
                        );
                    } else {
                        this.filterList[name] = this.$route.query[name];
                    }
                }
            });
        },
        filterListToQuery(filterList) {
            let filterObj = {};
            Object.keys(filterList).forEach(filterName => {
                if (
                    filterName == "dateRange" ||
                    filterName == "selectedDoctors"
                ) {
                    //object dan array
                     if (filterName == "dateRange") {
                        if (!_.isEmpty(filterList[filterName])) {
                            filterObj[filterName] = btoa(
                                JSON.stringify(filterList[filterName])
                            );
                        }
                     } else {
                         filterObj[filterName] = btoa(
                             JSON.stringify(filterList[filterName])
                         );
                     }
                } else {
                    filterObj[filterName] = filterList[filterName];
                }
            });
            return filterObj;
        },
        initPermissionCheck() {
            let arrayPermission = localStorage.getItem("permission").split(",");
            this.permissionObject = PermissionClass.isRenderByPermission(
                arrayPermission
            );
        },
        emitFilterQuery() {
            Event.$emit(
                "vue-tables.absent_table.filter::filterQuery",
                this.filterList
            );
        },
        deleteAllFilter() {
            this.filterList = {};
            this.emitFilterQuery();
        },
        showPopUp(message, title, icon) {
            let messageStr = message ? message : "";
            let titleStr = title ? title : "";
            let iconStr = icon ? icon : "";
            new SweetAlert().show(
                {
                    title: titleStr,
                    text: messageStr,
                    allowOutsideClick: true,
                    textConfirm: "Ok",
                    timer: 3000
                },
                iconStr
            );
        },
        showErrorPopUp(err) {
            let message = "Unknown Error Has Occurred";
            let title = "Error";

            if (err.message) {
                message = err.message;
            }
            if (err.error) {
                title = err.error;
            }
            new SweetAlert().show(
                {
                    title: title,
                    text: message,
                    allowOutsideClick: true,
                    reverseButtons: true,
                    showCancelButton: false,
                    textConfirm: "OK"
                },
                "dangerImg"
            );
        },
        deleteIndvFIlter(payload) {
            let { category, value } = payload;
            if (category == "dateRange") {
                delete this.filterList.dateRange;
            }
            if (category !== "selectedDoctors") {
                const asArray = Object.entries(this.filterList);

                const filtered = asArray.filter(([key, v]) => {
                    return v != value.replace(key, "");
                });

                const newFIlterList = Object.fromEntries(filtered);
                this.filterList = newFIlterList;
            } else if (category == "selectedDoctors") {
                this.filterList[category] = this.filterList[category].filter(
                    item => {
                        return item.nama != value;
                    }
                );
            }
            // else if(category == 'dateRange'){

            // }

            this.emitFilterQuery();
        },
        filterItemsData() {
            let payload = [];
            for (let key in this.filterList) {
                if (key !== "selectedDoctors" && key !== "dateRange") {
                    if (this.filterList[key] != null) {
                        payload.push({
                            category: key,
                            value: this.filterList[key]
                        });
                    }
                } else if (key == "selectedDoctors") {
                    this.filterList[key].forEach(el => {
                        payload.push({
                            category: key,
                            value: el.nama
                        });
                    });
                } else if (key == "dateRange") {
                    if (this.filterList.dateRange.start) {
                        payload.push({
                            category: key,
                            value: `${moment(
                                this.filterList.dateRange.start
                            ).format("DD/MMM/YYYY")} - ${moment(
                                this.filterList.dateRange.end
                            ).format("DD/MMM/YYYY")}`
                        });
                    }
                }
            }
            return payload;
        },
        controllerReqFunction(query) {
            let data = query;
            let params = {};
            params.page = data.page;
            params.page_size = data.limit;
            if (!_.isEmpty(this.filterList)) {
                data.filterQuery = this.filterList;
            }
            if (!data.filterQuery && !data.orderBy) {
                this.isFetching = true;
                return ketidakhadiranRepo
                    .getKetidakhadiran({ params })
                    .catch(async err => {
                        console.log(err);
                        this.showErrorPopUp(err);
                    });
            } else {
                let queryNames = {
                    id: "id",
                    tanggal: "tanggal",
                    hari: "hari",
                    selectedDoctors: "selectedDoctors"
                };
                let hariToNum = {
                    Senin: 0,
                    Selasa: 1,
                    Rabu: 2,
                    Kamis: 3,
                    Jumat: 4,
                    Saptu: 5,
                    Minggu: 6
                };
                let sortNames = {
                    tanggalKetidakhadiran: "sort"
                };
                let requestParam = createRequestOption(params);
                if (data.orderBy) {
                    let order = data.ascending ? "asc" : "desc";
                    requestParam.params.append(sortNames[data.orderBy], order);
                }
                Object.keys(data.filterQuery).forEach(query => {
                    if (query != "selectedDoctors") {
                        if (data.filterQuery[query]) {
                            if (query == "hari") {
                                requestParam.params.append(
                                    queryNames[query],
                                    hariToNum[data.filterQuery[query]]
                                );
                            } else if (query == "dateRange") {
                                if (data.filterQuery.dateRange.start) {
                                    requestParam.params.append(
                                        "tanggalMulai",
                                        data.filterQuery.dateRange.start
                                    );
                                    requestParam.params.append(
                                        "tanggalSelesai",
                                        data.filterQuery.dateRange.end
                                    );
                                }
                            } else {
                                requestParam.params.append(
                                    queryNames[query],
                                    data.filterQuery[query]
                                );
                            }
                        }
                    } else {
                        data.filterQuery[query].forEach(el => {
                            requestParam.params.append(
                                queryNames[query],
                                el.id
                            );
                        });
                    }
                });
                this.isFetching = true;
                return ketidakhadiranRepo
                    .getKetidakhadiran(requestParam)
                    .catch(async err => {
                        console.log(err, "controllerReqFunction error");
                        console.log(
                            err.message,
                            "controllerReqFunction error 119"
                        );
                        this.showErrorPopUp(err);
                    });
            }
        },
        controllerRespFunction(resp) {
            let classData = DataClass.getKetidakhadiran(resp.data);
            // console.log(resp.config.params, "133 response");
            this.isFetching = false;
            return {
                data: classData,
                count: resp.headers["x-count"]
                // resp.headers["x-count"]
            };
        },
        validateFilterData(data) {
            let payload = { ...data };
            Object.keys(payload).forEach(key => {
                if (key == "selectedDoctors") {
                    if (!payload[key].length > 0) {
                        delete payload[key];
                    }
                }else if(key == "dateRange") {
                    if(!payload.dateRange.start){
                        delete payload.dateRange;
                    }
                }
                else if (!payload[key]) {
                    delete payload[key];
                }
            });
            return payload;
        },
        openModalFilter() {
            let closeModalFunc;
            let payload = {
                initialData: this.filterList,
                action: data => {
                    console.log(data);
                    const validated = this.validateFilterData(data);
                    console.log(validated, "validated");
                    this.filterList = validated;
                    this.emitFilterQuery();
                    closeModalFunc();
                },
                searchDoctor: async name => {
                    return await this.getDoctorDataDropdown(name);
                }
            };
            let { close } = ModalService.open(KetidakhadiranFilter, payload);
            closeModalFunc = close;
        },
        async deleteAbsentConfirmation({ id, nama }) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning">
                        <p>${nama}</p>
                        <p> ID: ${id} </p>
                     </div>`;
                html += `<p class="sub-delete__text">Ketidakhadiran dokter akan terhapus secara permanen</p>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title:
                            "Apakah Anda yakin ingin menghapus ketidakhadiran dokter berikut?",
                        html: html,
                        // text: "Data tarif akan terhapus secara permanen",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: "Ya, hapus data",
                        textCancel: "Tidak, batalkan"
                    },
                    "dangerImg"
                );
                if (result.value) {
                    this.deleteAbsentData(id);
                }
            } catch (error) {
                //    if (typeof unblock == "function") {
                //         unblock();
                //     }
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async deleteAbsentData(id) {
            let unblock;
            try {
                unblock = this.blockUi.blockPage();
                const resp = await ketidakhadiranRepo.delKetidakhadiran(id);
                unblock();
                if (resp.error) {
                    throw resp.error;
                }
                this.emitFilterQuery();
                this.showPopUp(
                    "Data ketidakhadiran berhasil dihapus",
                    "success",
                    "successImg"
                );
            } catch (error) {
                if (typeof unblock == "function") {
                    unblock();
                }
                this.showErrorPopUp(error);
            }
        },
        async addAbsentConfirmation({ id, nama }) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning"  style="background: #F3F8FF;">
                        <p>${nama}</p>
                        <p> ID: ${id} </p>
                     </div>`;
                html += `<p class="sub-delete__text">Ketidakhadiran dokter akan ditambah</p>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title:
                            "Apakah Anda yakin ingin menambah ketidakhadiran dokter berikut?",
                        html: html,
                        // text: "Data tarif akan terhapus secara permanen",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: "Ya, Tambah",
                        textCancel: "Tidak, Batalkan"
                    },
                    "warningImg"
                );
                return result;
            } catch (error) {
                //    if (typeof unblock == "function") {
                //         unblock();
                //     }
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        openKetidakhadiranModal() {
            let closeModalFunc;
            let unblock;
            let payload = {
                getJadwalByDokterId: this.ketidakHadiranByDokterId,
                action: async data => {
                    try {
                        console.log(data, "data 228");
                        const { jadwal, alasan, nama, id } = data;
                        const payload = DataClass.postKetidakhadiran({
                            jadwal,
                            alasan
                        });
                        // console.log(payload, "payload");
                        if (payload) {
                            const result = await this.addAbsentConfirmation({
                                nama,
                                id
                            });
                            console.log(result, "result swal");
                            if (result.value) {
                                console.log(payload, "payload");
                                unblock = this.blockUi.blockModal();
                                const resp = await ketidakhadiranRepo.postKetidakhadiran(
                                    payload
                                );
                                unblock();
                                if (resp.error) {
                                    throw resp;
                                } else {
                                    closeModalFunc();
                                    this.emitFilterQuery();
                                    this.showPopUp(
                                        "Data Ketidakhadiran berhasil ditambah",
                                        "success",
                                        "successImg"
                                    );
                                }
                            }
                        }
                    } catch (error) {
                        this.showErrorPopUp(error);
                    }
                },
                searchDoctor: async name => {
                    return await this.getDoctorDataDropdown(name);
                }
            };
            let { close } = ModalService.open(AddKetidakhadiranModal, payload);
            closeModalFunc = close;
        },
        async ketidakHadiranByDokterId(id) {
            try {
                const ketidakhadirans = await ketidakhadiranRepo.getKetidakhadiranByDokterId(
                    id
                );
                // DataClass.jadwalsMapper(ketidakhadirans);
                if (ketidakhadirans.error) {
                    throw resp.error;
                }
                return DataClass.jadwalsMapper(ketidakhadirans);
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async getDoctorDataDropdown(searchQuery) {
            try {
                // console.log(searchQuery, "searchQuery");
                let params = {
                    page_size: 20
                };
                if (searchQuery) {
                    params.nama = searchQuery;
                    params.page_size = 1000;
                }
                const resp = await ketidakhadiranRepo.getDoctorList(params);
                // this.dropDownData.doctorOptions = resp;
                if (resp.error) {
                    throw resp;
                }
                return resp;
            } catch (error) {
                console.log(error, "getDoctorDataDropdown");
                this.showErrorPopUp(error);
            }
        }
    },
    created() {
        this.initPermissionCheck();
        this.routeQueryToFilterList();
    }
};
</script>

<style></style>
