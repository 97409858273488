export default class Ketidakhadiran {
    static getKetidakhadiran(data) {
        if (data) {
            if (Array.isArray(data)) {
                return data.map(item => {
                    return {
                        id: item.ID,
                        tanggalKetidakhadiran: this.dateFormat(item.tanggal),
                        nama: item.jadwal ? this.dokterData(item.jadwal) : "",
                        // .dokter.nama,
                        hari: item.jadwal ? this.days(item.jadwal.hari) : "",
                        alasan: item.alasan ? item.alasan : "",
                        jamMulai: item.jadwal
                            ? item.jadwal.jamMulai.slice(0, -3)
                            : "",
                        jamSelesai: item.jadwal
                            ? item.jadwal.jamSelesai.slice(0, -3)
                            : ""
                    };
                });
            }
        }
    }
    static dokterData(data) {
        let nama = ``;
        if (data) {
            if (data.dokter) {
                nama = data.dokter.nama;
            }
        }
        return nama;
    }
    static days(day) {
        const days = {
            SUNDAY: "Minggu",
            MONDAY: "Senin",
            TUESDAY: "Selasa",
            WEDNESDAY: "Rabu",
            THURSDAY: "Kamis",
            FRIDAY: `Jumat`,
            SATURDAY: "Sabtu",
            0: "Senin",
            1: "Selasa",
            2: "Rabu",
            3: "Kamis",
            4: "Jumat",
            5: "Sabtu",
            6: "Minggu"
        };
        if (days[day]) {
            return days[day];
        }
    }
    static dateFormat(date) {
        return moment(date, "YYYY-MM-DD").format("DD/MMM/YYYY");
    }
    static postKetidakhadiran(data) {
        if (data) {
            if (Array.isArray(data.jadwal)) {
                return data.jadwal.map(item => {
                    return {
                        // id: null,
                        // tanggal: this.dateFormat(item.tanggal),
                        // nama: item.jadwal.dokter.nama,
                        // hari: this.days(item.jadwal.hari),
                        jadwal: item.jadwalObject,
                        status: "CANCELLED",
                        alasan: data.alasan,
                        tanggal:
                            moment(item.tanggal).format("YYYY-MM-DD") +
                            "T00:00:00Z"
                    };
                });
            }
        }
    }
    static jadwalsMapper(data) {
        if (data) {
            return data.map(item => {
                return {
                    ...item,
                    hari: this.days(item.hari)
                };
            });
        }
    }
}
