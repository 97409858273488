<template>
    <div class="action-button">
        <button
            @click.prevent="deleteMethod"
            class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
            title="Hapus"
        >
            <TrashIcon />
        </button>
    </div>
</template>

<script>
// import EditIcon from "../../_icons/EditIcon.vue";
// import EyeIcon from "../../_icons/EyeIcon.vue";
import TrashIcon from "../../_icons/TrashIcon.vue";
export default {
    components: {
        // EditIcon,
        // EyeIcon,
        TrashIcon
    },
    props: {
        rowData: {
            type: Object
        },
        buttonMethod: {
            type: Object
        }
    },
    methods: {
        deleteMethod() {
            if (this.buttonMethod) {
                if (
                    typeof this.buttonMethod.deleteAbsentConfirmationMethod ==
                    "function"
                ) {
                    this.buttonMethod.deleteAbsentConfirmationMethod(
                        this.rowData
                    );
                }
            }
        }
    }
};
</script>

<style></style>
